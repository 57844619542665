<template>
	<v-row class="mx-0" align="center">
		<v-text-field
				readonly
				:value="typeof new_val === 'object' ? new_val.name : new_val"
				outlined
				dense
				hide-details
				class="rounded-lg paragraph"
				:type="type"
				@keyup="(e) => $emit('keyup', e)"
				:height="30"
				:disabled="readonly"
				:background-color="backgroundColor"
				:placeholder="placeholder"
				@click="new_val ? '': browseFile()"
		>
			<template v-slot:prepend-inner>
				<v-tooltip top open-delay="500">
					<template v-slot:activator="{on}">
					<v-btn v-on="on" x-small :height="30" depressed color="primary" style="max-width: 30px; max-height: 30px; position: relative; top: -8px; left: -12px; margin-right: -6px;" @click="browseFile()" class="rounded-0 rounded-l-lg">
						<v-icon size="14" @click="browseFile()">mdi-folder-open</v-icon>
					</v-btn>
					</template>
					<span>{{ $t('single_trad.FileField.browse') }}</span>
				</v-tooltip>
			</template>
			<template v-slot:append>
				<v-icon v-if="clearable && new_val !== '' && new_val !== null && typeof new_val !== 'undefined'" size="18" @click="new_val = ''" style="position: relative; bottom: 2px; right: -3px;">mdi-close</v-icon>
				<v-icon v-if="appendIcon" size="18" class="ml-2" @click="iconClick" style="position: relative; bottom: 2px;">{{ appendIcon }}</v-icon>
			</template>

			<template v-slot:label v-if="label">
				<div class="caption-1" style="margin-top: -4px;">{{ label }}</div>
			</template>
		</v-text-field>

		<input
				type="file"
				hidden
				ref="file_upload"
				@change="(e) => preloadPicture(e)"
		/>
	</v-row>
</template>

<script>
	export default {
		name: "FileField",
		props: {
			value: {},
			label: {type: String},
			backgroundColor: {type: String, default: 'cell'},
			type: {type: String},
			appendIcon: {type: String},
			clearable: {type: Boolean},
			readonly: {type: Boolean},
			iconClick: {type: Function, default: () => {}},
			placeholder: {}
		},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		},
		methods: {
			browseFile() {
				this.$refs['file_upload'].click()
			},
			preloadPicture(e) {
				const maxSize = 2000000

				if (e.target.files.length) {
					//if (e.target.files[0].size < maxSize) {
						this.new_val = e.target.files[0]
					//}
				}
			},
		},
		mounted(){
			this.$emit('ready')
		}
	}
</script>

<style scoped>

</style>